export default {
  main: {
    padding: '10px'
  },
  dropdown: {
    display: 'flex'
  },
  button: {
    padding: '5px'
  }
}