export const app = {
  audioFiles: [],
  cycle: false,
  files: [],
  isAtBeginning: true,
  isPlaying: false,
  options: [], 
  progress: 0.0,
  selectedFileId: null,
  selectedFileName: null
};